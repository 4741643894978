import React, { useEffect } from "react";
import Hero from "./Hero";
import { useLocation } from "react-router-dom";
import fleetBg from "../../assets/img/map-ai.jpg";
const SolutionsFleetGo = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/solutions-fleet") {
      const element = document.getElementById("solutions-fleet");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.pathname]);
  return (
    <main className="main">
      <Hero />
      <section id="solutions-fleet" className="services section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Integrated Fleet Solution</h2>
          <p>
            <strong>
              Integrated solution with power of analytics and machine learning
              algorithm
            </strong>
          </p>
          <p>
            Higher productivity, higher utilisation, lower admin cost, higher
            profit, safer and more sustainable, all in one integrated platform -
            <strong> Mobitra</strong>'s vision to support you at every level.
          </p>
          <p>
            Use our solution and data metrics we provide from your operations
            and respond to our recommendation, we are your partner in business
            and always at work behind the scene for your better future.
          </p>
        </div>
        <div
          className="container mb-5 text-center"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <img
            src={fleetBg}
            alt=""
            className="img-fluid"
            style={{ maxHeight: "500px" }}
          />
        </div>
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="100">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-code-square"></i>
                </div>
                <div>
                  <h4 className="title">
                    <p className="stretched-link">
                      Integrated SaaS Solution (Software as a service)
                    </p>
                    {/* <a href="services-details.html" className="stretched-link">
                      Lorem Ipsum
                    </a> */}
                  </h4>
                  <p className="description">
                    All our products and solution are cloud based and fuelled by
                    artificial intelligence and machine learning technologies 
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="200">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-bar-chart"></i>
                </div>
                <div>
                  <h4 className="title">
                    <p className="stretched-link">
                      Predictive Analysis and Forecast
                    </p>
                    {/* <a href="services-details.html" className="stretched-link">
                      Dolor Sitema
                    </a> */}
                  </h4>
                  <p className="description">
                    Use the power of data to understand you fleet metrics better
                    and listen to our recomendations, we are there to help you
                    with your own data
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="300">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-people"></i>
                  {/* <i className="bi bi-card-checklist"></i> */}
                </div>
                <div>
                  <h4 className="title">
                    <p className="stretched-link">
                      Full Customer Experience Service
                    </p>
                    {/* <a href="services-details.html" className="stretched-link">
                      Sed ut perspiciatis
                    </a> */}
                  </h4>
                  <p className="description">
                    We are bunch of highly motivated people and we are available
                    24*7 at your 
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="400">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-calendar4-week"></i>
                </div>
                <div>
                  <h4 className="title">
                    <p className="stretched-link">
                      Highly Flexible & Customisable Solution 
                    </p>
                    {/* <a href="services-details.html" className="stretched-link">
                      Magni Dolores
                    </a> */}
                  </h4>
                  <p className="description">
                    Our Software is highly scalable, available to meet
                    customer's requirements. Very smooth on-boarding process to
                    provide the best customer experience in a most optimised
                    manner without any downtime
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="500">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-binoculars"></i>
                </div>
                <div>
                  <h4 className="title">
                    <p className="stretched-link">
                      Safer Fleet & Safer Solution
                    </p>
                    {/* <a href="services-details.html" className="stretched-link">
                      Nemo Enim
                    </a> */}
                  </h4>
                  <p className="description">
                    Safety of fleet and response to any incident  is the core of
                    our product design principles and security risk is the key
                    to our solution design and hence all our solutions goes
                    through stringnent safety and security risk assesment
                    upfront.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="600">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-brightness-high"></i>
                </div>
                <div>
                  <h4 className="title">
                    <p className="stretched-link">Sustainable Solution</p>
                    {/* <a href="services-details.html" className="stretched-link">
                      Eiusmod Tempor
                    </a> */}
                  </h4>
                  <p className="description">
                    This can involve a range of practices optimizing routes,
                    implementing eco-driving techniques, reducing idling time,
                    and maintaining vehicles to manage fleet of vehicles with a
                    focus on reducing the environmental impact and promoting
                    sustainability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <div className="row gy-4">
            <div className="col-lg-12 " data-aos="fade-up" data-aos-delay="400">
              <div className="service-item d-flex justify-content-center">
                <div>
                  <h4 className="title">
                    <p className="stretched-link">
                      Contact Customer Care For Further Query Or Assistance
                    </p>
                  </h4>
                  <p
                    style={{
                      zIndex: 2,
                      position: "relative",
                      textWrap: "wrap",
                    }}
                  >
                    <span>+91 80811 75758</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SolutionsFleetGo;
